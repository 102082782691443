import React from 'react';
import { Link } from 'docz'

import capellaLogo from '../../../assets/images/capella-logo-2048x2048.png';

// Hardcoding in the styles temporarily. This should not be a final solution
// the goal would be to implement stylesheets and import it into the wrapper
// component, implementing any style overrides there

const linkStyles = {
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  color: '#23222b',
  fontWeight: 'bold',
  textDecoration: 'none'
}

const imageStyles = {
  display: 'inline-block',
  width: '40px',
  margin: '0 1em 0 0'
}

export const Logo = () => {
  return (
    <Link to="/" style={linkStyles}>
      <img src={capellaLogo} alt="Capella Space" style={imageStyles} />
      Capella Space Documentation
    </Link>
  )
}